import { getSignedUrl } from "@aws-sdk/s3-request-presigner";
import { useCallback, useEffect, useState } from "react";
import {
  GetObjectCommand,
  _Object,
} from "@aws-sdk/client-s3";
import prettyBytes from "pretty-bytes";
import { useInjection } from "inversify-react";
import { Button } from "react-bootstrap";
import { Domain } from "../models/Domain";
import { useTranslation } from "react-i18next";
import { SortableTable } from "./SortableTable";
import { AWSClientProvider } from "../services/AWSClientProvider";
import { API } from "../services/HTTP";
import { File } from "../models/File";
import { PageSelector } from "./PageSelector";
import { ConfirmModal } from "./ConfirmModal";
import { UserService } from "../services/User";
import { ListFileAction } from "../pages/FilesPage";
import { FilterParams } from "../pages/FilesPage";

export function ListFiles({ 
        domain, 
        patientId, 
        setFilesLoading, 
        itemsPerPage = 25, 
        isMultipleSelected, 
        action, 
        filterParams
    } : { 
        domain?: Domain, 
        patientId?: string, 
        setFilesLoading: (loading: boolean) => void, 
        filterParams?: FilterParams,
        itemsPerPage?: number, 
        isMultipleSelected?: boolean, 
        action?: ListFileAction,
    }) {

    const [t] = useTranslation();

    const api = useInjection<API>("API");

    const s3ClientProvider = useInjection<AWSClientProvider>(AWSClientProvider);
    const user = useInjection<UserService>(UserService);

    // Map the page number to the next token (= the key that tells the api which files to fetch)
    const [nextTokenMap, setNextTokenMap] = useState(new Map<number, string | null>());

    const [renderedFileColumns, setRenderedFileColumns] = useState<any[]>([]); // What files are currently shown
    const [fileData, setFileData] = useState<any[]>([]); 
    const [loading, setLoading] = useState(false); // What directories are currently shown
    const [sortBy, setSortBy] = useState<string>("updated_at");
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);
    const [totalPages, setTotalPages] = useState<number>(1);
    const [isLastPageReached, setIsLastPageReached] = useState<boolean>(false);
    const [isError, setIsError] = useState<boolean>(false); 
    const [isAdmin, setIsAdmin] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string>("");

    const [archiving, setArchiving] = useState(false);
    const [showArchiveObjectModal, setShowArchiveObjectModal] = useState(false);
    const [object2Archive, setObject2Archive] = useState<string>();
    const [currentSelection, setCurrentSelection] = useState<string[]>([]);
    const [selectedObjects, setSelectedObjects] = useState<string[]>([]);
    const [showArchiveListModal, setShowArchiveListModal] = useState(false);
    const [showDownloadListModal, setShowDownloadListModal] = useState(false);

    function removeEmpty(param: any) {
        return Object.fromEntries(Object.entries(param).filter(([_, v]) => v != null));
    }    
    
    const reload = useCallback(() => {
        setLoading(true);
        setFilesLoading(true);
    
        const fetchData = async () => {
            if (!domain) return;
            const {
                selectedDevices: devices = [],
                selectedSizeRange = null,
                selectedModDateRange = [],
                selectedRecDateRange = []
            } = filterParams || {};
    
            const [minSize = null, maxSize = null] = selectedSizeRange?.map(v => 2 ** v) || [];
            const [startModDate = null, endModDate = null] = selectedModDateRange || [];
            const [startRecDate = null, endRecDate = null] = selectedRecDateRange || [];
            const formattedModStartDate = startModDate ? startModDate.toDate().getTime() / 1000 : null;
            const formattedModEndDate = endModDate ? endModDate.toDate().getTime() / 1000 : null;
            const formattedRecStartDate = startRecDate ? startRecDate.toDate().getTime() / 1000 : null;
            const formattedRecEndDate = endRecDate ? endRecDate.toDate().getTime() / 1000 : null;
    
            const nextToken = nextTokenMap.get(currentPageNumber - 1);

            const params = {
                items: itemsPerPage,
                sort: sortBy,
                order: sortOrder,
                devices: devices.length > 0 ? devices.join() : null,
                minSize: minSize,
                maxSize: maxSize,
                startMod: formattedModStartDate,
                endMod: formattedModEndDate,
                startRec: formattedRecStartDate,
                endRec: formattedRecEndDate,
                next_token: nextToken
            };
            
            const cleanedParams = removeEmpty(params);
            
            const url = `domains/${domain.Id}${patientId ? `/patients/${patientId}` : ''}/files`;
                
            try {
                const response = await api.get(url, { params: cleanedParams });
                const { files: fetchedFiles, nextToken: fetchedNextToken } = response.data;
    
                if (fetchedFiles) {
                    setFileData(fetchedFiles);
                }
    
                if (fetchedNextToken) {
                    setNextTokenMap(prevMap => {
                        const newMap = new Map(prevMap);
                        newMap.set(currentPageNumber, fetchedNextToken);
                        return newMap;
                    });
                    setTotalPages(prev => Math.max(prev, currentPageNumber + 1));
                } else {
                    setIsLastPageReached(true);
                }
            } catch (error) {
                console.error('Error fetching files:', error);
            } finally {
                setLoading(false);
                setFilesLoading(false);
            }
        };
    
        user.isAdmin().then(admin => {
            setIsAdmin(admin);
            fetchData();
        });
    }, [domain, sortBy, sortOrder, itemsPerPage, currentPageNumber, nextTokenMap, filterParams]);
    
    useEffect(() => {
        setNextTokenMap(new Map());
        setTotalPages(1);
        setCurrentPageNumber(1);
        setIsLastPageReached(false);
    
        reload(); // Trigger the reload directly after resetting
    }, [sortBy, sortOrder, itemsPerPage, filterParams]);

    useEffect(() => {
        reload();
    }, [domain, currentPageNumber])
    
    const downloadFile = useCallback(async (key: string) => {
        if(!domain) {
            console.error("No domain selected.")
            return;
        }

        let filename = key.replaceAll('/', '_');
        if(filename.endsWith('.dat'))
            filename = filename.slice(0, filename.length - 4) + '.csem_dat'

        const command = new GetObjectCommand({ Bucket: domain.bucket, Key: key, ResponseContentDisposition: `attachment; filename=${filename}`});
        const s3Client = await s3ClientProvider.getS3Client(domain);
        const url = await getSignedUrl(s3Client, command, { expiresIn: 3 * 60 });
        window.open(url, '_blank', 'noreferrer');
    }, [domain]);



    const columns = [
        {name: "", key: "select", disabled: true},
        {name: "Last Modified", key: "updated_at", format: (item: string) => new Date(item).toLocaleString(t('dateFormat'))},
        {name: "Recorded At", key: "recorded_at", info: "Hi", format: (item: string) => (item ? new Date(item).toLocaleString(t('dateFormat')) : '-')},
        {name: "Name", key: "file_name"},
        {name: "Size", key: "size", format: (item: number) => item ? prettyBytes(item) : '-'},
        {name: "Action", key: "button", disabled: true}
    ];

    const preprocessFiles = useCallback((files: File[]) => {
        return files.map(file => ({
            file_name: file.key?.split("/").join("_"),
            updated_at: file.updated_at,
            recorded_at: file.recorded_at ?? null,
            size: file.size,
            button: <>
            <Button onClick={(e) => { e.preventDefault(); downloadFile(file.key!);}} title={t("Download")}><i className="bi bi-download"></i></Button>
            {isAdmin && (
                <Button 
                    variant="danger"
                    style={{marginLeft: "5px"}}
                    title={t("Archive")}
                    onClick={(e) => { 
                        e.preventDefault(); 
                        setObject2Archive(file.key!);
                        onArchiveObject();}}>
                        <i className="bi bi-archive"></i>
                </Button>
            )}
            </>,
            select: <>
                {isMultipleSelected ? 
                (<input
                type="checkbox"
                name="selectObject"
                key={file.key}
                checked={currentSelection.includes(file.key)}
                onChange={() => {
                    currentSelection.includes(file.key) ?
                    setCurrentSelection(currentSelection.filter(path => path !== file.key)) : 
                    setCurrentSelection([...currentSelection, file.key]);                               
                }}/>) : (<></>)}</>
        }));
    }, [downloadFile, isAdmin, isMultipleSelected, currentSelection])

    useEffect(() => {
        setRenderedFileColumns(preprocessFiles(fileData));
    }, [preprocessFiles, fileData])

    const onArchiveObject = (() => setShowArchiveObjectModal(true));

    const archiveObject = useCallback(async (path: string) => {
        if(!domain) {
            console.error("No domain selected.")
            return;
        }
        setArchiving(true);

        const encodedPath = encodeURIComponent(path);

        await api.get(`domains/${domain.Id}/archive-files?path=${encodedPath}`)
    }, [domain])

    const archiveObjects = useCallback(async (list: string[]) => {
        try {
            list.map(path => {
                archiveObject(path);
            });
        } catch (error) { 
            console.error("Archiving error:", error)
        }
        setCurrentSelection([]);
        reload();
        setShowArchiveListModal(false);
        setArchiving(false);
    }, [reload, archiveObject])

    const downloadObjects = useCallback(async (list: string[]) => {
        try {
            list.map(path => {
                console.log(path)
                downloadFile(path)
            })
        } catch (error) {
            console.error("Download error:", error)
        }
        setCurrentSelection([]);
        reload();
        setShowDownloadListModal(false);
    }, [reload, downloadFile])

    useEffect(() => {
        if(action == "Download") {
            setSelectedObjects(currentSelection)
            setShowDownloadListModal(true)
        } else if (action == "Archive") {
            setSelectedObjects(currentSelection)
            setShowArchiveListModal(true)
        } else if (action == "SelectAll") {
            setCurrentSelection(fileData.map(file => file.key))
        } else if (action == "UnselectAll") {
            setCurrentSelection([])
        } else if(!isMultipleSelected && action=="") {
            setCurrentSelection([])
        }
    }, [action, isMultipleSelected, currentPageNumber]);

    return <>
    <div className="mb-4 d-flex flex-column" style={{ height: "100%" }}>
    <div className="mb-4" style={{ flexGrow: 1, width: "100%" }}>
        <SortableTable
            columns={columns} 
            data={renderedFileColumns} 
            loading={loading} 
            setSort={setSortBy} 
            setOrder={setSortOrder}/> 
    </div>
    <div className="mb-4 d-flex justify-content-end">
        <PageSelector 
            currentPageNumber={currentPageNumber} 
            totalPages={totalPages!} 
            isLastPageReached={isLastPageReached}
            setCurrentPageNumber={setCurrentPageNumber}
        />
    </div>
    </div>
    <ConfirmModal
        showModal={showArchiveObjectModal}
        setShowModal={setShowArchiveObjectModal}
        title="Sure Archive File"
        buttonText="Archive"
        activeButtonText="Archiving"
        action={archiveObjects}
        item={[object2Archive]}
    ></ConfirmModal>

    <ConfirmModal
        showModal={showArchiveListModal}
        setShowModal={setShowArchiveListModal}
        title={`Are you sure you want to archive ${selectedObjects.length} file(s)?`}
        buttonText="Archive"
        activeButtonText="Archiving"
        action={archiveObjects}
        item={selectedObjects}
        text={selectedObjects.join("\n\n")}
    ></ConfirmModal>

    <ConfirmModal
        showModal={showDownloadListModal}
        setShowModal={setShowDownloadListModal}
        title={`Are you sure you want to download ${selectedObjects.length} file(s)?`}
        buttonText="Download"
        activeButtonText="Downloading"
        action={downloadObjects}
        item={selectedObjects}
        text={selectedObjects.join("\n\n")}
    ></ConfirmModal>
    </> 
}