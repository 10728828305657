import { useTranslation } from "react-i18next";
import { ReactNode, useState } from "react";
import { Spinner } from "react-bootstrap";

export type ColumnWithAggregate<T> = {
    // Name of the column as shown to the user.
    name: string;

    // Whether an info text displas when hovering over the column name
    info?: string;

    // Whether or not to be able to sort by this col.
    disabled?: boolean;

    // If you want to render some node based on multiple entries of that row.
    aggregate: (row: T) => ReactNode;
}

export type ColumnWithKey<T> = Omit<ColumnWithAggregate<T>, 'aggregate'> & {
    // Key of the array given in `data`
    key: string;

    // If you want to format the value for displaying it.
    format?: (value: any) => ReactNode;
}

export type Column<T> = ColumnWithKey<T> | ColumnWithAggregate<T>

interface SortableTableProps<T> {
    columns: Column<T>[];
    data: T[];
    loading: boolean;
    setSort: (sortBy: string) => void;
    setOrder: (sortOrder: 'asc' | 'desc') => void;
}

export function SortableTable<T>({ columns, data, loading, setSort, setOrder }: SortableTableProps<T>) {
    const [t, i18n] = useTranslation();
    const [sortBy, setSortBy] = useState<string>("updated_at");
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

    const handleSort = ((key: string) => {
        if (key === sortBy) {
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            setOrder(sortOrder === 'asc' ? 'desc' : 'asc');
        } else {
            setSortBy(key);
            setSort(key);
            setSortOrder('asc');
            setOrder('asc');
        }
    })

    const sortedData = [...data].sort((a: any, b: any) => {
        if (sortBy) {
            const aValue = a[sortBy];
            const bValue = b[sortBy];
            if (sortOrder === 'asc') {
                return aValue < bValue ? -1 : aValue > bValue ? 1 : 0;
            } else {
                return bValue < aValue ? -1 : bValue > aValue ? 1 : 0;
            }
        } else {
            return 0;
        }
    });

    const renderAggregateTableCell = (item: T, column: ColumnWithAggregate<T>) => {
        return <td key={column.aggregate.toString()}>
            <span key={column.aggregate.toString()}>
                {column.aggregate(item)} <br />
            </span>
        </td>
    }

    const renderKeyTableCell = (item: T, column: ColumnWithKey<T>) => {
        const value = (item as any)[column.key];
        if (column.key && Array.isArray(value)) {
            return (
                <td key={column.key}>
                    {value.map((val: any, index: number) => (
                        <span key={index}>
                            {column.format ? column.format(val) : val} <br />
                        </span>
                    ))}
                </td>
            );
        }

        return (
            <td key={column.key}>
                {column.format ? column.format(value) : value}
            </td>
        );
    }

    const renderTableCell = (item: T, column: Column<T>) => {
        if ('aggregate' in column) {
                return renderAggregateTableCell(item, column as ColumnWithAggregate<T>);
        } else {
                return renderKeyTableCell(item, column as ColumnWithKey<T>)
        }
    };

    let tableBodyComp = <></>
    if (loading) {
        tableBodyComp = <tr>
            <td colSpan={columns.length}>
                <Spinner></Spinner>
            </td>
        </tr>
    } else if (sortedData.length == 0) {
        tableBodyComp = <tr>
            <td colSpan={columns.length}>
                {t("Nothing to show...")}
            </td>
        </tr>
    } else {
        tableBodyComp = <>{sortedData.map((item, index) => (
            <tr key={index}>
                {columns.map((column) => renderTableCell(item, column))}
            </tr>
        ))}</>
    }

    return <>
        <table className="table">
            <thead>
                <tr>
                    {columns.map((column) => {
                        const key = 'aggregate' in column ? column.aggregate.toString() : column.key
                        return (
                            column.disabled ?
                                <th key={key}>
                                    {t(`${column.name}`)}
                                </th> :
                                <th key={key} onClick={() => handleSort(key)} style={{ cursor: "pointer" }}>
                                    {t(`${column.name}`)} {column.info ? <i className="bi bi-info-circle" title={t("When sorting by Recorded Date, only LEAP and DELTA files will be displayed.")} style={{ cursor: "help" }}></i> : <></>} &emsp; 
                                    {sortBy === key && (
                                        <i className={`bi ${sortOrder === 'desc' ? 'bi-caret-up-fill' : 'bi-caret-down-fill'}`} ></i>
                                    ) || (<i className={`bi bi-chevron-expand`}></i>)}
                                </th>
                        )
                    })}
                </tr>
            </thead>
            <tbody>
                {tableBodyComp}
            </tbody>
        </table>
    </>
}