import { injectable, inject } from "inversify";
import * as HTTP from "./HTTP";
import { CofemoTimestreamResponse, GenericTimestreamResponse } from "../models/TimestreamResponse";
import _ from "lodash";

@injectable()
export class FetchTokenizedTimestreamData {
    @inject("API")
    public api!: HTTP.API;

    @inject("PublicAPI")
    public publicApi!: HTTP.PublicAPI;

    private mergeCofemoResponses(oldResponse: CofemoTimestreamResponse, newResponse: CofemoTimestreamResponse) {
        return {
            timestamps: [...oldResponse.timestamps, ...newResponse.timestamps],
            received_timestamp: [...oldResponse.received_timestamp, ...newResponse.received_timestamp],
            core_measurement_quality: [...oldResponse.core_measurement_quality, ...newResponse.core_measurement_quality],
            core_temperature: [...oldResponse.core_temperature, ...newResponse.core_temperature],
            core_skin_temperature: [...oldResponse.core_measurement_quality, ...newResponse.core_measurement_quality],
            core_battery_level: [...oldResponse.core_battery_level, ...newResponse.core_battery_level],
            alert_temperature: oldResponse.alert_temperature || newResponse.alert_temperature
              ? [...(oldResponse.alert_temperature || []), ...(newResponse.alert_temperature || [])]
              : undefined
              
              
          };
    }

    private mergeResponses(oldResponse: GenericTimestreamResponse, newResponse: GenericTimestreamResponse) {
        for (const key in newResponse) {
            if (oldResponse.hasOwnProperty(key)) {
                oldResponse[key] = oldResponse[key].concat(newResponse[key]);
            } else {
                oldResponse[key] = newResponse[key];
            }
        }
        return oldResponse;
    }

    public async fetchTokenizedData (url: string, signal?: AbortSignal) {
        
        const response = await this.api.get<{data: GenericTimestreamResponse, nextToken: string}>(url, { signal });
        let data = response.data.data;
        let r = _.cloneDeep(response);
        while (r.data.nextToken) {
            r = await this.api.get<{data: GenericTimestreamResponse, nextToken: string}>(`${url}&next_token=${r.data.nextToken}`, { signal });
            data = this.mergeResponses(data, r.data.data)
        }

        return data
    }

    public async fetchTokenizedCofemoData(url: string, isPublic: boolean) {

        const apiToUse = isPublic ? this.publicApi : this.api;
        
        const response =await apiToUse.get<{data: CofemoTimestreamResponse, nextToken: string | null}>(url);
        let seriesResponse = response.data.data;
        let r = _.cloneDeep(response);
        while (r.data.nextToken) {
            r = await apiToUse.get<{data: CofemoTimestreamResponse, nextToken: string}>(`${url}&next_token=${r.data.nextToken}`);
            seriesResponse = this.mergeCofemoResponses(seriesResponse, r.data.data)
        }
        
        return seriesResponse
    }
}