import React, { useState } from 'react';
import { Button, Alert, Spinner, Form, Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { API } from '../services/HTTP';
import { useInjection } from 'inversify-react';

interface GenerateTokenComponentProps {
    domainId: string;
}

const GenerateTokenComponent: React.FC<GenerateTokenComponentProps> = ({ domainId }) => {
    const api = useInjection<API>("API");
    const [t] = useTranslation();

    const [tokenName, setTokenName] = useState<string>('');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState<string | null>(null);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const generateToken = async () => {
        setLoading(true);
        setSuccessMessage(null);
        setErrorMessage(null);

        try {
            const token = generateUniqueToken();
            await api.post<any>(`domains/${domainId}/onboarding-tickets`, {
                token: token,
                name: tokenName,
                claim: true
            });

            setSuccessMessage(`Token : ${token}`);
        } catch (error) {
            setErrorMessage(t('Failed to generate token. Please try again.'));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div>
            <Form.Group className="mb-3">
                <Row>
                    <Col xs={8}>
                        <Form.Control
                            type="text"
                            placeholder={t('Token name must be at least 6 characters long.')}
                            value={tokenName}
                            onChange={(e) => setTokenName(e.target.value)}
                        />
                    </Col>

                    <Col xs={4}>
                        <Button
                            variant="primary"
                            onClick={generateToken}
                            disabled={loading || tokenName.length < 6}
                            className="w-100"
                        >
                            {loading ? (
                                <>
                                    <Spinner animation="border" size="sm" /> {t('Generating...')}
                                </>
                            ) : (
                                t('Generate Token')
                            )}
                        </Button>
                    </Col>
                </Row>
            </Form.Group>

            {successMessage && (
                <Alert variant="success" onClose={() => setSuccessMessage(null)} dismissible>
                    {successMessage}
                </Alert>
            )}

            {errorMessage && (
                <Alert variant="danger" onClose={() => setErrorMessage(null)} dismissible>
                    {errorMessage}
                </Alert>
            )}
        </div>
    );
};

function generateUniqueToken(): string {
    const timestamp = Date.now().toString(36); 
    const randomPart = Math.random().toString(36).substring(2, 16);
    return randomPart + timestamp;
}

export default GenerateTokenComponent;
