export type Attribute = {
    incoming_key: string,
    storage_key: string,
    type: string,
    attribute_unit?: string,
    display_types?: string[],
    is_graph_title?: boolean
}
export enum DisplayType {
    LATEST = "Latest",
    GRAPH = "Graph"
}
  
export const displayTypeOptions: string[] = [
    DisplayType.LATEST.toString(),
    DisplayType.GRAPH.toString()
];