import React, { useCallback, useEffect, useRef, useState } from "react";
import { useInjection } from "inversify-react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import { API } from "../services/HTTP";
import { DomainUser } from "../models/DomainUser";
import { SortableTable } from "../components/SortableTable";
import { UserService } from "../services/User";
import { ConfirmModal } from "../components/ConfirmModal";

export function UserManagementPage() {

    const { t } = useTranslation();
    
    const user = useInjection<UserService>(UserService);
    const api = useInjection<API>("API");

    const { domain_id } = useParams();
    const [isAdmin, setIsAdmin] = useState(false);

    const [sortBy, setSortBy] = useState<string>();
    const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc');

    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<any>([]);
    const [error, setError] = useState<string | null>(null);

    const [isAddingUser, setIsAddingUser] = useState<boolean>(false);
    const [showAddUserModal, setShowAddUserModal] = useState(false);
    const [showDeleteUserModal, setShowDeleteUserModal] = useState(false);
    const [user2Modify, setUser2Modify] = useState<DomainUser>()

    const reload = useCallback(async () => {
        setLoading(true);
        if (!domain_id) return;

        try {
            const response = await api.get<DomainUser[]>(`user-management/domains/${domain_id}/users?in_domain=${!isAddingUser}`);
            setUsers(preprocessUsers(response.data));
        } catch (err) {
            console.log("Error fetching the users", error)
            setError(String(error))
        } finally {
            setLoading(false);
        }
    }, [domain_id, api, isAddingUser])

    const modifyUserRecords = useCallback(async () => {
        const method = isAddingUser ? 'put' : 'delete';
        const url = `user-management/domains/${domain_id}/users`;
        const data = isAddingUser ? { userName: user2Modify!.userName } : { data: { userName: user2Modify!.userName } };
        await api[method]<DomainUser[]>(url, data);
        reload();
    }, [isAddingUser, domain_id, api, user2Modify, reload])

    useEffect(() => {
        reload();
        user.isAdmin().then(admin => setIsAdmin(admin));
    }, [user, domain_id, reload, isAddingUser]);

    const onAddUser = useCallback(() => {
        setIsAddingUser(true);
    }, [])

    const preprocessUsers = useCallback((users: DomainUser[]) => {
        return users.map(user => ({
            name: user.name,
            email: user.email,
            role: user.role,
            button: <>
            {isAddingUser ? (
                <Button 
                onClick={(e) => { 
                    e.preventDefault(); 
                    setUser2Modify(user);
                    setShowAddUserModal(true);
                }}>
                    <i className="bi bi-person-plus-fill"></i>
                </Button>
            ) : (
                <Button variant="outline-danger" 
                onClick={(e) => { 
                    e.preventDefault(); 
                    setUser2Modify(user);
                    setShowDeleteUserModal(true)
                }}>
                    <i className="bi bi-person-dash-fill"></i>
                </Button>
            )}
            </>
        }));
    }, [isAddingUser])

    const displayUsers = useCallback(() => {
        return (
            <SortableTable 
                columns={columns}
                data={users}
                loading={loading}
                setSort={setSortBy}
                setOrder={setSortOrder}
            />
        );
    }, [users, loading, setSortBy, setSortOrder])

    const columns = [
        { name: "User name", key: "name" },
        { name: "Email address", key: "email" },
        { name: "Role", key: "role" },
        { name: t("Action"), key: "button", disabled: true }
    ];

    return <>
        <div className="header-container">
            <div>
                <h1 className="header-title">
                    {isAddingUser ? t("Add a user") : t("User List")}
                </h1>
                <small className="header-domain">
                    {t("Domain")}: {domain_id}
                </small>
            </div>
            <div>
                {!isAddingUser && (<Button 
                onClick={(e) => {e.preventDefault(); onAddUser()}} 
                type="button" 
                variant="outline-primary"
                style={{marginRight: '10px'}}>
                    + {t('Add Users')}
                </Button>)}
                <Button disabled={loading} onClick={(e) => {e.preventDefault(); reload()}} variant="outline-secondary">
                    {loading ? (
                        <Spinner 
                        as="span"
                        animation="border"
                        size="sm"
                        role="status" />
                    ) : (
                        <i className="bi bi-arrow-clockwise"></i>
                    )}
                </Button>
            </div>
        </div>
        {isAddingUser && (
            <div>
                <Button 
                onClick={(e) => {e.preventDefault(); setIsAddingUser(false)}}
                variant="outline-secondary"
                style = {{ border: "none" }}>
                    <i className="bi bi-chevron-left"></i> {t("Back")}
                </Button>
            </div>)}
        <div className={isAddingUser ? "table-border" : ""}>
            {displayUsers()}
        </div>

        <ConfirmModal
            showModal={showAddUserModal}
            setShowModal={setShowAddUserModal}
            title="Sure Add User"
            buttonText="Add"
            activeButtonText="Adding"
            action={modifyUserRecords}
            item={user2Modify}
            text={user2Modify?.name}
        ></ConfirmModal>

        <ConfirmModal
            showModal={showDeleteUserModal}
            setShowModal={setShowDeleteUserModal}
            title="Sure Remove User"
            buttonText="Remove"
            activeButtonText="Removing"
            action={modifyUserRecords}
            item={user2Modify}
            text={user2Modify?.name}
        ></ConfirmModal>
    </>
}
