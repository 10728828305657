import { ResponsiveChartContainerPro, ZoomData } from "@mui/x-charts-pro";
import { useEffect, useState } from "react";
import { ChartsGrid, ChartsTooltip, ChartsXAxis, ChartsYAxis, LinePlot } from "@mui/x-charts";
import { Paper } from "@mui/material";

export function CSVGraphComponent({timeHeader, keyToLabel, data }: { timeHeader: string, keyToLabel: {[key: string]: string;}, data: Record<string, number>[]}) {

    const [zoom, setZoom] = useState<ZoomData[]>([
        {
            axisId: 'x-axis-id',
            start: 0,
            end: 100,
        },
    ]);

    return <>
        {Object.keys(keyToLabel).map((key) => (
            <>
            <h1 className="header-title" style={{ textAlign: "center" }}>{keyToLabel[key]}</h1>
            <Paper sx={{ width: '100%', height: 300, marginY: '10px' }} elevation={3}>
                <ResponsiveChartContainerPro
                zoom={zoom}
                onZoomChange={setZoom}
                series={[{
                    type: 'line',
                    dataKey: key,
                    label: keyToLabel[key] ?? key
                }]}
                xAxis={[{
                    dataKey: timeHeader,
                    id: "x-axis-id",
                    scaleType: "utc",
                    // @ts-ignore
                    zoom: true
                }]}
                dataset={data}
                >
                    <LinePlot />
                    <ChartsXAxis label="Timestamp" position="bottom" axisId="x-axis-id" />
                    <ChartsYAxis label="Y Axis" />
                    <ChartsGrid horizontal={true}/>
                    <ChartsTooltip />
            </ResponsiveChartContainerPro>
            </Paper>
            </>
        ))}
    </>
}