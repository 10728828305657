import React, { useCallback, useEffect, useState } from "react";
import { useInjection } from "inversify-react";
import { useTranslation } from "react-i18next";
import { API } from "../services/HTTP";
import { ExportData } from "../services/ExportData";
import { PickDate } from "../components/PickDate";
import { GenericTimestreamResponse } from "../models/TimestreamResponse";
import { Button, Modal, ProgressBar, Spinner } from "react-bootstrap";
import { GraphViewComponent } from "../components/GraphViewComponent";
import { Settings } from "../models/Settings";
import { DisplayType } from "../models/Attribute";
import _ from "lodash";
import { FetchTokenizedTimestreamData } from "../services/FetchTokenizedTimestreamData";
import { useParams } from "react-router-dom";
import { DomainHeader } from "../components/DomainHeader";

type DashboardImageType = 'center' | 'left' | 'right';

const getDashBoardImage = (dashBoardPartnerId: string, imageType: DashboardImageType) => {
    try {
        switch (imageType) {
            case 'center':
                return require(`../assets/${dashBoardPartnerId}/dashboard-center.png`);
            case 'left':
                return require(`../assets/${dashBoardPartnerId}/dashboard-left.png`);
            case 'right':
                return require(`../assets/${dashBoardPartnerId}/dashboard-right.png`);
            default:
                return null;
        }
    } catch (error) {
        return null;
    }
};


type TimeStampedValue = { 
    value: any; 
    timestamp: any; 
};
type GroupedGraphData = {
    [attributeKey: string]: {
        attribute_unit: string;
        sources: {
            [source: string]: TimeStampedValue[];
        };
    };
};
export function DashboardPage() {
    const TIMESTAMPS = 'timestamps'
    const TYPE_ATTRIBUTE_DOUBLE_ARRAY = "DOUBLEARRAY"
    const TYPE_ATTRIBUTE_INT_ARRAY = "INTARRAY"
    const { domain_id } = useParams();

    const api = useInjection<API>("API");
    const exportData = useInjection<ExportData>(ExportData);
    const fetchTimestreamData = useInjection<FetchTokenizedTimestreamData>(FetchTokenizedTimestreamData);

    const { t } = useTranslation();

    // 15 Minutes, will make it configurable in future.
    const refreshInterval = 15 * 60 * 1000

    const [downloading, setDownloading] = useState(false);
    const [downloadProgress, setDownloadProgress] = useState(0);
    const [loading, setLoading] = useState<boolean>(true);
    const [timeSeriesGraphInfo, setTimeSeriesGraphInfo] = useState<GenericTimestreamResponse | null>();
    const [selectedDate, setSelectedDate] = useState<Date>(new Date(`${new Date().toISOString().substring(0, 10)}`));
    const [sensorInfo, setSensorInfo] = useState<Record<string, any> | null>(null);
    const [settings, setCurrentSettings] = useState<Settings | null>()
    const [groupedGraphData, setGroupGraphData] = useState<GroupedGraphData | null>();
    const [dashboardCenterLogo, setDashboardCenterLogo] = useState<string | null>(null);
    const [dashboardLeftLogo, setDashboardLeftLogo] = useState<string | null>(null);
    const [dashboardRightLogo, setDashboardRightLogo] = useState<string | null>(null);

    const groupGraphData = (data: GenericTimestreamResponse | null) => {
        const result: GroupedGraphData = {};
        if (!data) {
            setGroupGraphData(result)
            return
        }
    
        const graphTitleAttributes = settings?.attributes?.filter(attr => attr.is_graph_title === true) || []    
        const graphableAttributes = settings?.attributes
            ?.filter(attr => !attr.is_graph_title && attr.type != TYPE_ATTRIBUTE_DOUBLE_ARRAY && attr.type != TYPE_ATTRIBUTE_INT_ARRAY)
            .map(attr => attr.incoming_key) || [];
    
        const groupedData: Map<string, Map<string, TimeStampedValue[]>> = new Map();
    
        graphableAttributes.forEach((attributeKey) => {
            const attrValues = data[attributeKey]

            if (!attrValues) return
    
            attrValues.forEach((attributeValue, index) => {
                if (!attributeValue) return
                const timestamp = data[TIMESTAMPS]?.[index]
                if (!timestamp) return

                const graphTitle = graphTitleAttributes
                    .map(attr => `${attr.incoming_key}=${data[attr.incoming_key]?.[index] ?? ""}`)
                    .join(", ");
                if (!groupedData.has(attributeKey)) {
                    groupedData.set(attributeKey, new Map());
                }
                const attributeMap = groupedData.get(attributeKey);
                if (attributeMap) {
                    if (!attributeMap.has(graphTitle)) {
                        attributeMap.set(graphTitle, [])
                    }
                    
                    attributeMap.get(graphTitle)?.push({ value: attributeValue, timestamp })
                }
            })

            for (const [attributeKey, attributeMap] of groupedData.entries()) {
                const attributeUnit = settings?.attributes?.find(attr => attr.incoming_key === attributeKey)?.attribute_unit || '';
                result[attributeKey] = {
                    attribute_unit: attributeUnit,
                    sources: Object.fromEntries(attributeMap.entries()),
                };
            }
            setGroupGraphData(result)
        })
    }

    function setDashboardLogo(dashboardPartnerId: string | undefined) {
        if (!dashboardPartnerId) return
        setDashboardLeftLogo(dashboardPartnerId)
        setDashboardCenterLogo(getDashBoardImage(dashboardPartnerId, `center`))
        setDashboardLeftLogo(getDashBoardImage(dashboardPartnerId, `left`))   
        setDashboardRightLogo(getDashBoardImage(dashboardPartnerId, `right`)) 
    }

    useEffect(() => {
        if (!settings) {
            reloadSettings()
        }
    }, [domain_id]);

    const reloadSettings = async () => {
        setLoading(true);
        try {
            const response = await api.get<Settings>(`domains/${domain_id}/settings`);
            const fetchedSettings = response.data;
            if (!_.isEqual(fetchedSettings, settings)) {
                const clonedSettings = _.cloneDeep(fetchedSettings);
                setCurrentSettings(clonedSettings)
                setDashboardLogo(clonedSettings.dashBoardPartnerId)
            }
        } catch (error) {
            console.error('Error downloading settings:', error);
        } finally {
        }
    };

    function getTimeSeriesData(
        displayType: DisplayType,
        currentSettings: Settings,
        timeSeriesData: GenericTimestreamResponse
    ): GenericTimestreamResponse | null {
        if (!timeSeriesData || !timeSeriesData.timestamps || !currentSettings || !currentSettings.attributes) {
            return null
        }
            
        const filteredTimeSeriesData: GenericTimestreamResponse = { }
        currentSettings.attributes.forEach(attr => {
            const key = attr.incoming_key
            const values = timeSeriesData[key]
            if (values) {
                if (displayType === DisplayType.LATEST) {
                    if (attr.display_types?.includes(DisplayType.LATEST)
                        && attr.type !== TYPE_ATTRIBUTE_DOUBLE_ARRAY 
                        && attr.type !== TYPE_ATTRIBUTE_INT_ARRAY 
                        && !attr.is_graph_title
                    ) {
                        let lastIndex = (timeSeriesData.timestamps.length - 1)
                        while (lastIndex >= 0) {
                            const recentValue = values[lastIndex]
                            if (recentValue) {
                                filteredTimeSeriesData[key] = [recentValue]; 
                                break    
                            }
                            lastIndex--
                        }
                    }
                } else {
                    filteredTimeSeriesData[key] = values;
                }
            }
        })
        if (displayType === DisplayType.GRAPH) {
            filteredTimeSeriesData.timestamps = timeSeriesData.timestamps;
        }
        return filteredTimeSeriesData
    }
    
    const handleButtonClick = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        setDownloading(true);
        setDownloadProgress(0)
        const startTimestamp = Math.floor(selectedDate.setHours(0, 0, 0) / 1000);
        const endTimestamp = Math.floor(selectedDate.setHours(23, 59, 59) / 1000);
        try {
            await exportData.downloadDomainData(domain_id!, startTimestamp, endTimestamp);
        } catch (error) {
            console.error('Error downloading domain data:', error);
        } finally {
            setDownloadProgress(1)
            setDownloading(false);
        }
    };

    const reload = useCallback(async (signal: AbortSignal) => {
        if (!settings)
            return

        try {
            setLoading(true)
            const startTimestamp = Math.floor(selectedDate.setHours(0, 0, 0) / 1000);
            const endTimestamp = Math.floor(selectedDate.setHours(23, 59, 59) / 1000);
            const url = `domains/${domain_id}/data?start=${startTimestamp}&end=${endTimestamp}`;
            const timeSeriesData = await fetchTimestreamData.fetchTokenizedData(url, signal);    
            const fetchedSensorInfo = getTimeSeriesData(DisplayType.LATEST, settings, timeSeriesData);
            const fetchedGraphInfo = getTimeSeriesData(DisplayType.GRAPH, settings, timeSeriesData);
            setSensorInfo(_.cloneDeep(fetchedSensorInfo));
            setTimeSeriesGraphInfo(_.cloneDeep(fetchedGraphInfo));
            groupGraphData(fetchedGraphInfo);
        } catch (err) {
            console.error(err);
        } finally {
            setLoading(false);
        }
    }, [settings, selectedDate]);
    
    useEffect(() => {
        if (!settings)
            return
        const controller = new AbortController();
        reload(controller.signal);
        return () => {
            controller.abort();
        };
    }, [settings, selectedDate]);

    useEffect(() => {
        if (!settings)
            return
        if (refreshInterval > 0 && (selectedDate?.toDateString() === new Date().toDateString())) {
            const controller = new AbortController();
            const signal = controller.signal;    
            const interval = setInterval(() => {
                reload(signal);
            }, refreshInterval);

            return () => {
                controller.abort();
                clearInterval(interval);
            }
        }  
    }, [refreshInterval]);

    function capitalizeFirstLetter(str: string): string {
        if (str.trim() === "") {
            return "-";
        }
        return str.charAt(0).toUpperCase() + str.slice(1);
    }
              
    const GraphDataDisplayView = ({ groupedGraphData, loading, selectedDate }: { groupedGraphData: GroupedGraphData | null | undefined, loading: boolean, selectedDate: Date }) => {
        return (
            <>
                    {loading && timeSeriesGraphInfo == null ? (
                        <Spinner animation="border" />
                    ) : (
                        <>
                    {!groupedGraphData || Object.keys(groupedGraphData).length === 0 ? (
                        <div
                            style={{
                                textAlign: 'center',
                                color: '#888',
                                fontSize: '1.5rem',
                                padding: '20px',
                                backgroundColor: '#f9f9f9',
                                marginTop: '40px',
                                borderRadius: '10px',
                                boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
                            }}
                        >
                            {t("Data Not Available")}
                        </div>
                    ) : (
                    Object.entries(groupedGraphData).map(([attributeKey, { attribute_unit, sources }]) => (
                        <div key={attributeKey} style={{ marginTop: '40px' }}>
                            {Object.entries(sources).map(([source, dataPoints]) => (
                                <div
                                    key={source}
                                    style={{
                                        position: 'relative',
                                        textAlign: 'center',
                                        marginBottom: '30px',
                                    }}
                                >
                                    <h4
                                        style={{
                                            position: 'absolute',
                                            top: '-20px',
                                            left: '5%',
                                            transform: 'translateX(-50%)',
                                            backgroundColor: 'rgba(255, 255, 255, 0.8)',
                                            padding: '5px 10px',
                                            borderRadius: '10px',
                                            boxShadow: '0 2px 4px rgba(0, 0, 0, 0.5)',
                                        }}
                                    >
                                        {`${source}`}
                                    </h4>
                                    <GraphViewComponent
                                        data={dataPoints.map(({ timestamp, value }) => ({
                                            x: new Date(timestamp),
                                            y: value,
                                        }))}
                                        graphTitle={attributeKey}
                                        selectedDate={selectedDate}
                                        yAxisLabel={`${attributeKey} (${attribute_unit || ''})`}
                                        chartType="line"
                                        lineTension={0.5}
                                    />
                                </div>
                            ))}
                        </div>
                    ))
                )}
            </>

                )}
            </>
        );
    };
    return (
        <>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    border: (dashboardLeftLogo || dashboardCenterLogo || dashboardRightLogo) ? '2px solid #ccc' : 'none',
                    backgroundColor: (dashboardLeftLogo || dashboardCenterLogo || dashboardRightLogo) ? '#f9f9f9' : 'transparent',
                    borderRadius: (dashboardLeftLogo || dashboardCenterLogo || dashboardRightLogo) ? '8px' : '0',
                    padding: (dashboardLeftLogo || dashboardCenterLogo || dashboardRightLogo) ? '16px' : '0'
                 }}>
                {dashboardLeftLogo && (
                    <img
                        src={dashboardLeftLogo}
                        alt="Left Logo"
                        style={{
                            width: '15%',
                            height: 'auto',
                            objectFit: 'contain'
                        }}
                    />
                )}
                {dashboardCenterLogo && (
                    <img
                        src={dashboardCenterLogo}
                        alt="Center Logo"
                        style={{
                            width: '35%',
                            height: 'auto',
                            objectFit: 'contain'
                        }}
                    />
                )}
                {dashboardRightLogo && (
                    <img
                        src={dashboardRightLogo}
                        alt="Right Logo"
                        style={{
                            width: '10%',
                            height: 'auto',
                            objectFit: 'contain'
                        }}
                    />
                )}
            </div>
            <div>
            <DomainHeader title={t("Dashboard")} domain={domain_id ?? ""}>
            <Button disabled={loading} onClick={(e) => {
                e.preventDefault()
                handleButtonClick(e)
            }} variant="outline-secondary">
                {loading && (
                    <Spinner 
                    as="span"
                    animation="border"
                    size="sm"
                    role="status" />
                ) || (
                    <i className="bi bi-download"></i>
                )}
            </Button>
        </DomainHeader>
            </div> 
            {loading && (
                <div className="spinner-container" style={{ marginTop: '8px' }}>
                    <Spinner animation="border" role="status">
                        <span className="visually-hidden">{t("Loading...")}</span>
                    </Spinner>
                </div>
            )}
            <div style={{ marginTop: '8px' }}>
                {timeSeriesGraphInfo != null && (
                    <div className="col-xxl-9 col-xl-9 col-lg-7 col-md-12 col-sm-12">
                        <PickDate onDateSelect={setSelectedDate} selectedDate={selectedDate} startDate={new Date()} />
                        <div>                            
                            {!loading && sensorInfo && Object.keys(sensorInfo).length > 0 && (
                                <div className="metadata">
                                    <h3>{t("Most Recent Readings")}</h3>
                                    <ul>
                                        {Object.entries(sensorInfo)
                                        .map(([key, value]) => (
                                            <li key={key}>
                                                <strong>{t(capitalizeFirstLetter(key))}: </strong>
                                                { value || "-"} {settings?.attributes?.find(attr => attr.incoming_key === key)?.attribute_unit || ""}
                                            </li>

                                        ))}
                                    </ul>
                                </div>
                            )}
                            <GraphDataDisplayView
                                groupedGraphData={groupedGraphData}
                                loading={loading}
                                selectedDate={selectedDate}
                            />
                        </div>
                    </div>
                )}
            </div>


            <Modal show={downloading} onHide={() => { }}>
                <Modal.Header>
                    <Modal.Title>{t("Collecting data...")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <ProgressBar animated now={downloadProgress} label={`${Math.round(downloadProgress)}%`} />
                </Modal.Body>
            </Modal>
        </>
    );
}
