import React from 'react';
import { Form } from 'react-bootstrap';
import { Settings } from '../../models/Settings';
import { useTranslation } from 'react-i18next';
import { APP_PAGES, FALLBACK_PAGE } from '../../models/AppPages';

interface GeneralSettingsProps {
    currentSettings: Settings;
    changeSetting: <K extends keyof Settings>(key: K, value: Settings[K]) => void;
    loading: boolean;
}

const GeneralSettingsComponent: React.FC<GeneralSettingsProps> = ({ currentSettings, changeSetting, loading }) => {
    const [t] = useTranslation();
    const handleCheckboxChange = (key: keyof Settings, currentValue: boolean) => {
        changeSetting(key, !currentValue);
    };
    const handleValueChange = (key: keyof Settings, value: string) => {
        changeSetting(key, value);
      };
      
    return (
        <>
            <Form.Group className="mb-3" controlId="cofemoDomain">
                <Form.Label className="h4"><strong>{t("Is this domain part of COFEMO?")}</strong></Form.Label>
                {loading ? (
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    </div>
                ) : (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="domainToggle"
                            checked={currentSettings?.isCofemoDomain ?? false}
                            onChange={() => handleCheckboxChange('isCofemoDomain', currentSettings?.isCofemoDomain)}
                        />
                    </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="biosenseiDomain">
                <Form.Label className="h4"><strong>{t("Is this domain part of BIOSENSEI?")}</strong></Form.Label>
                {loading ? (
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    </div>
                ) : (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="domainToggle"
                            checked={currentSettings?.isBiosenseiDomain ?? false}
                            onChange={() => handleCheckboxChange('isBiosenseiDomain', currentSettings?.isBiosenseiDomain)}
                        />
                    </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="parsingEnabled">
                <Form.Label className="h4"><strong>{t("Enable parsing on incoming data")}</strong></Form.Label>
                {loading ? (
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    </div>
                ) : (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="parsingToggle"
                            checked={currentSettings?.parsingEnabled ?? false}
                            onChange={() => handleCheckboxChange('parsingEnabled', currentSettings?.parsingEnabled)}
                        />
                    </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="deviceAdditionOnPostEnabled">
                <Form.Label className="h4"><strong>{t("Enable seamless addition of device on post data")}</strong></Form.Label>
                {loading ? (
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    </div>
                ) : (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="deviceAdditionToggle"
                            checked={currentSettings?.deviceAdditionOnPostEnabled ?? false}
                            onChange={() => handleCheckboxChange('deviceAdditionOnPostEnabled', currentSettings?.deviceAdditionOnPostEnabled)}
                        />
                    </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="showFilesSection">
                <Form.Label className="h4"><strong>{t("Show Files")}</strong></Form.Label>
                {loading ? (
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    </div>
                ) : (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="filesToggle"
                            checked={currentSettings?.showFilesSection ?? true}
                            onChange={() => handleCheckboxChange('showFilesSection', currentSettings?.showFilesSection ?? true)}
                        />
                    </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="showEventsSection">
                <Form.Label className="h4"><strong>{t("Show Events")}</strong></Form.Label>
                {loading ? (
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    </div>
                ) : (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="showEventsSectionToggle"
                            checked={currentSettings?.showEventsSection ?? true}
                            onChange={() => handleCheckboxChange('showEventsSection', currentSettings?.showEventsSection ?? true)}
                        />
                    </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="showWearablesSection">
                <Form.Label className="h4"><strong>{t("Show Wearables")}</strong></Form.Label>
                {loading ? (
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    </div>
                ) : (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="wearablesToggle"
                            checked={currentSettings?.showWearablesSection === undefined || currentSettings?.showWearablesSection === null ? true : currentSettings.showWearablesSection}
                            onChange={() => handleCheckboxChange('showWearablesSection', currentSettings?.showWearablesSection ?? true)}
                        />
                    </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="isDashboardEnabled">
                <Form.Label className="h4"><strong>{t("Show Dashboard")}</strong></Form.Label>
                {loading ? (
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    </div>
                ) : (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="dashboardToggle"
                            checked={currentSettings?.isDashboardEnabled ?? false}
                            onChange={() => handleCheckboxChange('isDashboardEnabled', currentSettings?.isDashboardEnabled)}
                        />
                    </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3" controlId="showDataSection">
                <Form.Label className="h4"><strong>{t("Show Data")}</strong></Form.Label>
                {loading ? (
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    </div>
                ) : (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="dataToggle"
                            checked={currentSettings?.showDataSection ?? true}
                            onChange={() => handleCheckboxChange('showDataSection', currentSettings?.showDataSection ?? true)}
                        />
                    </div>
                )}
            </Form.Group>

            <Form.Group className="mb-3 w-auto" controlId="defaultAppLoadPage">
                <Form.Label className="h4">
                    <strong>{t("Set Default On Load Page")}</strong>
                </Form.Label>
                {loading ? (
                    <Form.Select className="form-control w-auto" disabled>
                    <option>{t("Loading...")}</option>
                    </Form.Select>
                ) : (
                    <Form.Select
                    className="form-control w-auto"
                    value={currentSettings?.defaultAppLoadPage ?? FALLBACK_PAGE}
                    onChange={(e) => handleValueChange("defaultAppLoadPage", e.target.value)}
                    >
                    {Object.entries(APP_PAGES).map(([key, value]) => (
                        <option key={key} value={key}>
                        {t(value)}
                        </option>
                    ))}
                    </Form.Select>
                )}
            </Form.Group>
        </>
    );
};

export default GeneralSettingsComponent;
