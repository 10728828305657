export const APP_PAGES = {
  files: "Files",
  patients: "Patients",
  "patients/archived": "Archived Patients",
  "events": "Events",
  data: "Data",
  devices: "Wearables",
  dashboard: "Dashboard",
} as const;

export const FALLBACK_PAGE = "files" as const;
