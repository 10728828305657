import { useInjection } from "inversify-react";
import { PublicAPI } from "../services/HTTP";
import { Button, Card, Row, Spinner } from "react-bootstrap";
import { useCallback, useEffect, useState } from "react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useQuery } from "../services/Utils";

export function AcknowledgeAlertPage() {
    const api = useInjection<PublicAPI>("PublicAPI");
    const query = useQuery();

    const [isAcknowledging, setIsAcknowledging] = useState(false);
    const [error, setError] = useState<string | null>(null);
    const [isAcknowledged, setIsAcknowledged] = useState<boolean | null>(null);
    const [t, i18n] = useTranslation();

    useEffect(() => {
        const f = async () => {
            const token = query.get("token");
            const alert = await api.get<any>(`public/acknowledge-alert?token=${token}`);
            setIsAcknowledged(alert.data.acknowledged_at != null);
        }
        f().catch(e => setError("Could not check whether alert is already acknowledged, the token may be invalid."))
    }, [query, api]);

    useEffect(() => {
        // This effect is only needed, if we don't have a default language yet.
        if (localStorage.getItem("DEFAULT_LANGUAGE") != null)
            return

        const lng = query.get("lng") ?? "en";
        localStorage.setItem("DEFAULT_LANGUAGE", lng);
        i18n.changeLanguage(lng);
    }, [query]);

    const acknowledge = useCallback(async (long_snooze: Boolean = false) => {
        setIsAcknowledging(true);
        setError(null);

        const token = query.get("token");
        try {
            await api.post(`public/acknowledge-alert`, undefined, { params: { token, long_snooze } });
            setIsAcknowledged(true);
        } catch (e) {
            console.error(e);
            setError("Could not acknowledge, try again later.")
        } finally {
            setIsAcknowledging(false);
        }
    }, [query, api])



    return <>
        <h1>COFEMO</h1>
        <Card style={{ maxWidth: "500px" }}>
            <Card.Header><i className="bi bi-exclamation-triangle"></i> {t("Possible Fever")}</Card.Header>
            <Card.Body>

                <Card.Text>
                    {t("acknowledge_alert_text")}
                </Card.Text>
                {error && (
                    <div className={`alert alert-danger`} role="alert">
                        {t(error)}
                    </div>
                )}
                {isAcknowledged === null && error === null && (<Spinner />)}

                {isAcknowledged === true && (
                    <div className={`alert alert-success`} role="alert">
                        {t("Alert already acknowledged")}
                    </div>)}

                {isAcknowledged === false && (
                    <>
                    <Row>
                        <Button
                            disabled={isAcknowledging}
                            onClick={(e) => { e.preventDefault(); acknowledge(false) }}
                            className="btn btn-success btn-lg" type="button">
                            {isAcknowledging && (<Spinner />) || (<>{t("Acknowledge and silence the alarm for 30 minutes")}</>)}
                        </Button>   
                    </Row>
                    <br></br>
                    <Row>
                        <Button
                            disabled={isAcknowledging}
                            onClick={(e) => { e.preventDefault(); acknowledge(true) }}
                            className="btn btn-warning btn-lg" type="button">
                            {isAcknowledging && (<Spinner />) || (<>{t("Acknowledge and silence the alarm for 3 days")}</>)}
                        </Button>
                    </Row>
                    </>
                )}


            </Card.Body>

        </Card>

    </>
}
