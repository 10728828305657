import React from 'react';
import {
  Chart as ChartJS,
  ChartOptions,
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LineElement,
  PointElement,
} from 'chart.js';
import { Line, Bar, Scatter } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import 'chartjs-adapter-date-fns';

ChartJS.register(
  LinearScale,
  TimeScale,
  Title,
  Tooltip,
  Legend,
  BarElement,
  CategoryScale,
  LineElement,
  PointElement
);

interface DataPoint {
  x: string | number | Date;
  y: number;
}

interface GraphViewProps {
  data: DataPoint[];
  selectedDate: Date;
  graphTitle: string;
  yAxisLabel: any;
  lineTension?: number;
  chartType: 'line' | 'bar' | 'scatter';
  stepSize?: number; // Add stepSize as an optional prop
}

const getRandomColor = () => {
  const randomInt = () => Math.floor(Math.random() * 255);
  return `rgba(${randomInt()}, ${randomInt()}, ${randomInt()}, 0.5)`;
};

export function GraphViewComponent({
  data,
  selectedDate,
  graphTitle,
  yAxisLabel,
  lineTension = 0.4,
  chartType = 'line',
  stepSize = 5, // Default value for stepSize
}: GraphViewProps) {
  const { t } = useTranslation();

  const sortedData = data.sort((a, b) => new Date(a.x).getTime() - new Date(b.x).getTime());
  const minTimestamp = sortedData.length
    ? new Date(sortedData[0].x).getTime()
    : new Date(selectedDate).setHours(0, 0, 0, 0);
  const maxTimestamp = sortedData.length
    ? new Date(sortedData[sortedData.length - 1].x).getTime()
    : new Date(selectedDate).setHours(23, 59, 59, 999);

  const options: ChartOptions<'line' | 'bar' | 'scatter'> = {
    responsive: true,
    layout: {
      padding: 5,
    },
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: `${graphTitle} - ${new Date(minTimestamp).toLocaleDateString(
          t('dateFormat'),
          {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }
        )}`,
      },
      tooltip: {
        enabled: true,
        mode: 'nearest',
        intersect: false,
        displayColors: false,
        callbacks: {
          title: (tooltipItems) => {
            const date = new Date(tooltipItems[0].parsed.x);
            return date.toLocaleString(t('dateFormat'), {
              year: 'numeric',
              month: 'long',
              day: 'numeric',
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
            });
          },
          label: (tooltipItem) => {
            return `${tooltipItem.dataset.label}: ${tooltipItem.parsed.y}`;
          },
        },
      },
    },
    scales: {
      x: {
        type: 'time',
        time: {
          unit: 'minute',
          displayFormats: {
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'MMM D',
          },
        },
        min: new Date(minTimestamp).toISOString(),
        max: new Date(maxTimestamp + 240000).toISOString(),
        ticks: {
          padding: 10,
          maxRotation: 0,
          minRotation: 0,
          autoSkip: true,
          autoSkipPadding: 15,
          stepSize: stepSize, // Use the stepSize prop here
        },
      },
      y: {
        type: 'linear',
        title: {
          display: true,
          text: yAxisLabel,
        },
        beginAtZero: true,
      },
    },
  };

  const chartData = {
    datasets: [
      {
        label: graphTitle,
        data: sortedData,
        borderColor: getRandomColor(),
        pointBackgroundColor: getRandomColor(),
        backgroundColor: getRandomColor(),
        tension: lineTension,
        spanGaps: true,
      },
    ],
  };

  let ChartComponent;
  switch (chartType) {
    case 'bar':
      ChartComponent = Bar;
      break;
    case 'scatter':
      ChartComponent = Scatter;
      break;
    case 'line':
    default:
      ChartComponent = Line;
      break;
  }

  return (
    <div style={{ position: 'relative', width: '100%', height: '400px' }}>
      <ChartComponent data={chartData} options={options} />
      {data.length === 0 && (
        <div
          style={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            color: '#888',
            fontSize: '1.5rem',
            textAlign: 'center',
            pointerEvents: 'none',
          }}
        >
          {t('No Data Available')}
        </div>
      )}
    </div>
  );
}
