import React from 'react';
import GenerateTokenComponent from '../GenerateTokenComponent';
import TokenListComponent from '../TokenListComponent';

interface OnboardingSettingsProps {
    domain_id: string;
}

const OnboardingSettingsComponent: React.FC<OnboardingSettingsProps> = ({ domain_id }) => {
    return (
        <>
            <div className="mb-3">
                <GenerateTokenComponent domainId={domain_id} />
                <TokenListComponent domainId={domain_id} />
            </div>

        </>
    );
};

export default OnboardingSettingsComponent;