import React from 'react';
import { Form, Tabs, Tab, Spinner, Button } from 'react-bootstrap';
import { Settings } from '../../models/Settings';
import { useTranslation } from 'react-i18next';
import { Attribute } from '../../models/Attribute';
import DashboardSettingsComponent from '../DashboardSettingsComponent';

interface TimeSeriesSettingsProps {
    currentSettings: Settings;
    changeSetting: <K extends keyof Settings>(key: K, value: Settings[K]) => void;
    changeAttribute: (index: number, value: string | string[] | boolean, key: keyof Attribute) => void;
    removeAttribute: (index: number) => void;
    setAttributes: React.Dispatch<React.SetStateAction<Attribute[]>>;
    attributes: Attribute[];
    loading: boolean;
    domain_id: string;
    errorMessage: { [key: string]: string };
    inKeyInput: React.RefObject<HTMLInputElement>;
    storageKeyInput: React.RefObject<HTMLInputElement>;
    typeInput: React.RefObject<HTMLSelectElement>;
}

const TimeSeriesSettingsComponent: React.FC<TimeSeriesSettingsProps> = ({
    currentSettings,
    changeSetting,
    changeAttribute,
    removeAttribute,
    setAttributes,
    attributes,
    loading,
    domain_id,
    errorMessage,
    inKeyInput,
    storageKeyInput,
    typeInput
}) => {
    const { t } = useTranslation();

    return (
        <>
            <Form.Group className="mb-3" controlId="timestreamRequired">
                <Form.Label className="h4"><strong>{t("Enable Timestream Database for Time-Dependent Data Storage?")}</strong></Form.Label>
                {loading ? (
                    <div className="form-check form-switch">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                    </div>
                ) : (
                    <div className="form-check form-switch">
                        <input
                            className="form-check-input"
                            type="checkbox"
                            id="timestreamToggle"
                            checked={currentSettings?.isTimestreamRequired}
                            onChange={() => changeSetting('isTimestreamRequired', !currentSettings?.isTimestreamRequired)}
                        />
                        <label className="form-check-label" htmlFor="timestreamToggle">
                            {currentSettings?.isTimestreamRequired ? t("Yes") : t("No")}
                        </label>
                    </div>
                )}
            </Form.Group>

            {currentSettings?.isTimestreamRequired && (
                <>
                    <Form.Group className="mb-3" controlId="dashboardEnabled" style={{ marginLeft: '10px' }}>
                        <Form.Label className="h4"><strong>{t("Enable Dashboard")}</strong></Form.Label>
                        {loading ? (
                            <div className="form-check form-switch">
                                <input className="form-check-input" type="checkbox" id="flexSwitchCheckDisabled" disabled />
                            </div>
                        ) : (
                            <div>
                                <div className="form-check form-switch">
                                <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id="dashboardToggle"
                                    checked={currentSettings?.isDashboardEnabled}
                                    onChange={() => changeSetting('isDashboardEnabled', !currentSettings?.isDashboardEnabled)}
                                />
                                <label className="form-check-label" htmlFor="dashboardToggle">
                                    {currentSettings?.isDashboardEnabled ? t("Yes") : t("No")}
                                </label>
                            </div>
                            <div>

                            </div>
                            </div>
                        
                        )}
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="dashboardPartnerId" style={{ marginLeft: '10px' }}>
                        <Form.Label className="h4"><strong>{t("Unique Partner ID for Dashboard")}</strong></Form.Label>
                        <Form.Control
                            type="text"
                            placeholder={t("Enter Partner ID")}
                            value={currentSettings.dashBoardPartnerId || ''}
                            onChange={(e) => changeSetting('dashBoardPartnerId', e.target.value)}
                        />
                    </Form.Group>
                    <Tabs variant="pills" defaultActiveKey="domainFields" id="uncontrolled-tab-example">
                        <Tab eventKey="domainFields" title="Domain Fields">
                            <Form.Group className="mb-3" controlId="domainfields">
                                {loading ? (
                                    <Spinner animation="border" />
                                ) : (
                                    <div className="container-fluid">
                                        {attributes.map((attribute, index) => (
                                            <div className="row" style={{ marginTop: "10px" }} key={index}>
                                                <Form.Group className="col-md-4">
                                                    {index === 0 && <Form.Label>{t("Attribute Name")}</Form.Label>}
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="device_id"
                                                        value={attribute.incoming_key}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            changeAttribute(index, e.target.value, "incoming_key");
                                                        }}
                                                        ref={inKeyInput}
                                                    />
                                                    {errorMessage[`incoming-${index}`] && <div className="text-danger">{t(errorMessage[`incoming-${index}`])}</div>}
                                                </Form.Group>

                                                <Form.Group className="col-md-5">
                                                    {index === 0 && <Form.Label>{t("Store in Timestream as")}</Form.Label>}
                                                    <Form.Control
                                                        type="text"
                                                        placeholder="core_device_id"
                                                        value={attribute.storage_key}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            changeAttribute(index, e.target.value, "storage_key");
                                                        }}
                                                        ref={storageKeyInput}
                                                    />
                                                    {errorMessage[`storage-${index}`] && <div className="text-danger">{t(errorMessage[`storage-${index}`])}</div>}
                                                </Form.Group>

                                                <Form.Group className="col-md-2">
                                                    {index === 0 && <Form.Label>{t("Type")}</Form.Label>}
                                                    <Form.Select
                                                        id="type"
                                                        value={attribute.type}
                                                        onChange={(e) => {
                                                            e.preventDefault();
                                                            changeAttribute(index, e.target.value, "type");
                                                        }}
                                                        ref={typeInput}
                                                    >
                                                        <option disabled>{attribute.type}</option>
                                                        <option value="VARCHAR">VARCHAR</option>
                                                        <option value="BOOLEAN">BOOLEAN</option>
                                                        <option value="DOUBLE">DOUBLE</option>
                                                        <option value="BIGINT">BIGINT</option>
                                                        <option value="TIMESTAMP">TIMESTAMP</option>
                                                        <option value="MULTI">MULTI</option>
                                                        <option value="INTARRAY">INTARRAY</option>
                                                        <option value="DOUBLEARRAY">DOUBLEARRAY</option>
                                                    </Form.Select>
                                                </Form.Group>

                                                <button
                                                    type="button"
                                                    className="btn btn-outline-danger col-md-1"
                                                    style={{ marginTop: "auto" }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        removeAttribute(index);
                                                    }}
                                                >
                                                    {t("Remove")}
                                                </button>
                                            </div>
                                        ))}
                                        <Button
                                            type="button"
                                            variant="outline-secondary"
                                            style={{ margin: "10px" }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                setAttributes([...attributes, { incoming_key: "", storage_key: "", type: "" }]);
                                            }}
                                        >
                                            + {t("Add an attribute")}
                                        </Button>
                                    </div>
                                )}
                            </Form.Group>
                        </Tab>
                        {currentSettings?.isDashboardEnabled && (
                            <Tab eventKey="dashboardFields" title="Dashboard Settings">
                                <DashboardSettingsComponent
                                    attributes={attributes}
                                    changeAttribute={changeAttribute}
                                    loading={loading}
                                />
                            </Tab>
                        )}
                    </Tabs>
                </>
            )}
        </>
    );
};

export default TimeSeriesSettingsComponent;
