import { useEffect, useCallback, useState } from 'react';
import { Navigate, useParams } from 'react-router-dom';
import { useInjection } from 'inversify-react';
import { DomainRepository } from '../services/DomainRepository';
import { Spinner } from 'react-bootstrap';
import { APP_PAGES, FALLBACK_PAGE } from '../models/AppPages';

const NavigateToDomainDefaultPageComponent = () => {
  const { domain_id } = useParams()
  const [ defaultPage, setDefaultPage ] = useState<string | null>(null);

  const domainRepository = useInjection(DomainRepository)

  useEffect(() => {
    if(!domain_id)
        return;

    domainRepository.get(domain_id).then(domain => {
        setDefaultPage(( domain?.settings?.defaultAppLoadPage && domain?.settings?.defaultAppLoadPage in APP_PAGES) ? domain?.settings?.defaultAppLoadPage : FALLBACK_PAGE);
    }).catch(error => {
        console.error("Failed to load domain settings:", error);
        setDefaultPage(FALLBACK_PAGE);
    });
    
}, [domain_id]);

if (defaultPage == null || domain_id == null)
    return <Spinner />

  return <Navigate to={defaultPage} />
}

export default NavigateToDomainDefaultPageComponent