import React, { useState, useEffect } from 'react';
import { Form, Spinner } from 'react-bootstrap';
import { MultiSelect } from 'react-multi-select-component';
import { Attribute, DisplayType } from '../models/Attribute';
import { t } from 'i18next';

interface Option {
    label: string;
    value: string;
}

interface DashboardSettingsProps {
    attributes: Attribute[];
    changeAttribute: (index: number, value: string | string[] | boolean, key: keyof Attribute) => void;
    loading: boolean;
}

const DashboardSettingsComponent: React.FC<DashboardSettingsProps> = ({ attributes, changeAttribute, loading }) => {
    const [dashboardAttributes, setDashboardAttributes] = useState<Attribute[]>(attributes);

    const displayOptions: Option[] = [
        { label: t("Latest"), value: DisplayType.LATEST },
        { label: t("Graph"), value: DisplayType.GRAPH }
    ];

    useEffect(() => {
        setDashboardAttributes(attributes);
    }, [attributes]);

    const handleAddDisplayTypes = (index: number, selected: Option[]) => {
        const updatedAttributes = [...dashboardAttributes];
        const attribute = updatedAttributes[index];

        const display_types = selected.map(option => option.value);
        attribute.display_types = display_types;
        setDashboardAttributes(updatedAttributes);
        changeAttribute(index, attribute.display_types, "display_types");
    };

    const handleAttributeUnitChange = (index: number, newValue: string) => {
        const updatedAttributes = [...dashboardAttributes];
        const attribute = updatedAttributes[index];
        attribute.attribute_unit = newValue;
        setDashboardAttributes(updatedAttributes);
        changeAttribute(index, attribute.attribute_unit, "attribute_unit");
    };


    const handleGraphCheckboxChange = (index: number) => {
        const isChecked = dashboardAttributes[index].is_graph_title ? false : true;
    
        const updatedAttributes = dashboardAttributes.map((attribute) => {
            return {
                ...attribute,
                is_graph_title: isChecked,
            };
        });
    
        setDashboardAttributes(updatedAttributes);
        changeAttribute(index, isChecked, "is_graph_title");
    };
    
    

    const getSelectedOptions = (attribute: Attribute): Option[] => {
        const addedDisplayTypes = attribute.display_types ?? [];
        return displayOptions.filter(option => addedDisplayTypes.includes(option.value));
    };

    return (
        <Form.Group className="mb-3" controlId="dashboardSettingsfields">
            {loading ? (
                <Spinner animation="border" />
            ) : (
                <div className="container-fluid">
                    {dashboardAttributes.map((attribute, index) => (
                        <div className="row" style={{ marginTop: "10px" }} key={index}>
                            <Form.Group className="col-md-2">
                                {index === 0 && <Form.Label>{t("Attribute Name")}</Form.Label>}
                                <Form.Control
                                    type="text"
                                    placeholder="device_id"
                                    disabled={true}
                                    value={attribute.incoming_key}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-2">
                                {index === 0 && <Form.Label>{t("Attribute Unit")}</Form.Label>}
                                <Form.Control
                                    type="text"
                                    placeholder="attribute_unit"
                                    value={attribute.attribute_unit ?? ""}
                                    onChange={(e) => handleAttributeUnitChange(index, e.target.value)}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-4">
                                {index === 0 && <Form.Label>{t("Display Type")}</Form.Label>}
                                <MultiSelect
                                    options={displayOptions}
                                    value={getSelectedOptions(attribute)}
                                    onChange={(selected: Option[]) => handleAddDisplayTypes(index, selected)}
                                    labelledBy={t("Select")}
                                />
                            </Form.Group>
                            <Form.Group className="col-md-2">
                                {index === 0 && <Form.Label>{t("Show as Graph Title")}</Form.Label>}
                                <Form.Check
                                    type="checkbox"
                                    checked={attribute.is_graph_title}
                                    onChange={() => handleGraphCheckboxChange(index)}
                                />
                            </Form.Group>
                        </div>
                    ))}
                </div>
            )}
        </Form.Group>
    );
};

export default DashboardSettingsComponent;
