import React, { useState, useEffect } from 'react';
import { Token } from '../models/Token';
import { useInjection } from 'inversify-react';
import { useTranslation } from 'react-i18next';
import { API } from '../services/HTTP';
import { Button, Modal, Spinner } from 'react-bootstrap';

interface TokenListComponentProps {
  domainId: string;
}

const TokenListComponent: React.FC<TokenListComponentProps> = ({ domainId }) => {
  const [tokens, setTokenList] = useState<Token[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const api = useInjection<API>("API");
  const { t } = useTranslation();
  const [showModal, setShowModal] = useState(false);

  const handleShow = () => {
    setShowModal(true);
    fetchTokens();
  };
  
  const handleClose = () => {
    setShowModal(false);
  };

  useEffect(() => {
    fetchTokens();
  }, []);

  const fetchTokens = async () => {
    setLoading(true);
    try {
      const response = await api.get<any>(`domains/${domainId}/onboarding-tickets`);
      setTokenList(response.data);
    } catch (error) {
      console.error('Failed to fetch tokens', error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Button variant="btn btn-outline-secondary" onClick={handleShow}>
        {t('Show All Tokens')}
      </Button>

      <Modal show={showModal} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>All Tokens</Modal.Title>
        </Modal.Header>
          <Modal.Body>
          {loading ? (
            <div className="text-center">
              <Spinner animation="border" role="status" />
              <p className="mt-2">{t("Loading tokens...")}</p>
            </div>
          ) : tokens.length > 0 ? (
            <div>
              <ul className="list-group list-group-flush">
                {tokens.map((token, index) => (
                  <li key={index} className="list-group-item d-flex justify-content-between align-items-center">
                    <div>
                      <strong>{t("Token Name")}</strong>: {token.name} { !token.claimed && (<strong>{t("Unclaimed")}</strong>) }<br /> 
                      <strong>{t("Token")}</strong>: {token.token} <br />
                      { token.patient_id && (<><strong>{t("Patient")}</strong>: {token.patient_id} <br /></>) }
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          ) : (
            <p className="text-center text-muted">{t("No tokens available.")}</p>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="btn btn-outline-secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default TokenListComponent;
