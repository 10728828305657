import { t } from "i18next";
import { ListFiles } from "../components/ListFiles";
import { useCallback, useEffect, useState } from "react";
import dayjs, { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { Button, Col, Container, Dropdown, Form, FormControl, ListGroup, Modal, ProgressBar, Row, Spinner } from "react-bootstrap";
import { ListFolders } from "../components/ListFolders";
import { useInjection } from "inversify-react";
import { UserService } from "../services/User";
import { useParams } from "react-router-dom";
import { Domain } from "../models/Domain";
import { DomainRepository } from "../services/DomainRepository";
import { S3, _Object } from "@aws-sdk/client-s3";
import { API } from "../services/HTTP";
import { File } from "../models/File";
import { DomainHeader } from "../components/DomainHeader";
import { Device } from "../models/Device";
import Slider from '@mui/material/Slider';
import { DateRangePicker } from '@mui/x-date-pickers-pro/DateRangePicker';
import { DateRange, LocalizationProvider, PickersShortcutsItem } from "@mui/x-date-pickers-pro";
import { List, ListItem } from "@mui/material";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

export type ListFileAction = "" | "Download" | "Archive" | "SelectAll" | "UnselectAll";
export type FilterParams = {
    selectedDevices: string[],
    selectedSizeRange: number[],
    selectedModDateRange: DateRange<Dayjs>,
    selectedRecDateRange: DateRange<Dayjs>,
}

export function FilesPage({ isProcessedPage } : { isProcessedPage: boolean }) {

    const user = useInjection<UserService>(UserService);
    const api = useInjection<API>("API");

    let { domain_id } = useParams();
    const [t, i18n] = useTranslation();
    
    const [loading, setLoading] = useState(false);
    const [filesLoading, setFilesLoading] = useState(false);
    const [domain, setDomain] = useState<Domain>();
    const domainRepository = useInjection<DomainRepository>(DomainRepository);
    const [isAdmin, setIsAdmin] = useState(false);
    const [syncing, setSyncing] = useState(false);
    const [numberOfFilesProcessed, setNumberOfFilesProcessed] = useState<number>(0);
    const [upToDateMessage, setUpToDateMessage] = useState<string>("");
    const [showMessage, setShowMessage] = useState(false);

    const [action, setAction] = useState<ListFileAction>("");
    const [isMultipleSelected, setIsMultipleSelected] = useState<boolean>(false);

    const [folderView, setFolderView] = useState<boolean>(true);
    const [itemsPerPage, setItemsPerPage] = useState<number>(50);

    const [showFilterModal, setShowFilterModal] = useState<boolean>(false);

    const defaultParams : FilterParams = {
        selectedDevices: [],
        selectedSizeRange: [0, 33],
        selectedModDateRange: [null, null],
        selectedRecDateRange: [null, null]
    }
    
    const [selectedDevices, setSelectedDevices] = useState<string[]>(defaultParams.selectedDevices);
    const [selectedSizeRange, setSelectedSizeRange] = useState<number[]>(defaultParams.selectedSizeRange);
    const [selectedModDateRange, setSelectedModDateRange] = useState<DateRange<Dayjs>>(defaultParams.selectedModDateRange);
    const [selectedRecDateRange, setSelectedRecDateRange] = useState<DateRange<Dayjs>>(defaultParams.selectedRecDateRange);

    const [filterParams, setFilterParams] = useState<FilterParams>(defaultParams)

    function valueLabelFormat(value: number) {
        const units = ['B', 'KB', 'MB', 'GB'];
      
        let unitIndex = 0;
        let scaledValue = value;
      
        while (scaledValue >= 1024 && unitIndex < units.length - 1) {
          unitIndex += 1;
          scaledValue /= 1024;
        }
      
        return `${scaledValue} ${units[unitIndex]}`;
    }

    const [devices, setDevices] = useState<string[]>([]);

    const fetchDevices = useCallback(async () => {
        if (!domain_id) return;
    
        setLoading(true);
        try {
          const response = await api.get<Device[]>(`domains/${domain_id}/devices`);
          const deviceNames = response.data.map(device => device.name);
          setDevices(deviceNames);
        } catch (error) {
          console.error('Error fetching devices:', error);
        } finally {
          setLoading(false);
        }
      }, [domain_id]);
    
      useEffect(() => {
        fetchDevices();
      }, [fetchDevices]);

    const reload = useCallback(async () => {
        setLoading(true);
        if (!domain_id)
            return

        const f = async () => {
            const domain = await domainRepository.get(domain_id!)
            setDomain({ ...domain! })
        };

        f().finally(() => setLoading(false));

    }, [domain_id, api, domainRepository])

    useEffect(() => {
        reload();
        user.isAdmin().then(admin => setIsAdmin(admin));
    }, [user, domain_id, reload]);

    const syncFiles = useCallback(async () => {

        setSyncing(true);
        setNumberOfFilesProcessed(0);

        const f = async () => {
            let response = await api.get(`domains/${domain_id}/sync-files`);
            let fileCount = response.data.numberOfFilesProcessed;
            let s3NextToken = response.data.s3NextToken;
            let dbNextToken = response.data.dbNextToken;
            setNumberOfFilesProcessed(fileCount);

            while (dbNextToken) {
                response = await api.get(`domains/${domain_id}/sync-files?s3_token=${s3NextToken}&db_token=${dbNextToken}`);
                fileCount += response.data.numberOfFilesProcessed;
                setNumberOfFilesProcessed(fileCount);
                s3NextToken = response.data.s3NextToken;
                dbNextToken = response.data.dbNextToken;
            }

            setUpToDateMessage(response.data.message);
        }

        f().finally(() => {
            reload();
            setSyncing(false);
            setShowMessage(true);
        });

    }, [domain_id, reload, api])

    useEffect(() => {
        if (showMessage) {
            const timer = setTimeout(() => {
                setShowMessage(false);
            }, 10000); // 10 seconds

            return () => clearTimeout(timer);
        }
    }, [showMessage]);

    useEffect(() => {
        if(!isMultipleSelected) {
            setAction("")
        }
    }, [isMultipleSelected])

    useEffect(() => {
        if (isProcessedPage) {
            setFolderView(true)
        }
    }, [isProcessedPage])

    const clearFilters =(() => {
        setSelectedDevices(defaultParams.selectedDevices);
        setSelectedSizeRange(defaultParams.selectedSizeRange);
        setSelectedModDateRange(defaultParams.selectedModDateRange);
        setSelectedRecDateRange(defaultParams.selectedRecDateRange);
        setFilterParams(defaultParams);
    })

    return <>
        <DomainHeader title={t(`${isProcessedPage ? "Processed ": ""}Files`)} domain={domain_id ?? ""}>
            {isAdmin && !isProcessedPage && (<>
                {showMessage ? (<>
                    <span className="text-success" style={{ marginRight: "10px" }}> <i className="bi bi-check-all"></i> {t(upToDateMessage)}</span>
                </>) : (<> </>)}
                <Button style={{ marginRight: '10px' }} variant="outline-secondary"
                    disabled={syncing}
                    onClick={(e) => {
                        e.preventDefault();
                        setUpToDateMessage("");
                        reload();
                        syncFiles()
                            .catch(error => console.error('Synchronisation error:', error));
                    }}>
                    {syncing ? (<>
                        <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status" /> {t("Syncing")}...
                    </>) :
                        (<>
                            <i className="bi bi-arrow-repeat"></i> {t("Sync files")}
                        </>)}
                </Button>
            </>
            )}
            <Button disabled={loading || filesLoading} onClick={(e) => { e.preventDefault(); reload() }} variant="outline-secondary">
                {(loading || filesLoading) && (
                    <Spinner
                        as="span"
                        animation="border"
                        size="sm"
                        role="status" />
                ) || (
                        <i className="bi bi-arrow-clockwise"></i>
                    )}
            </Button>
        </DomainHeader>

        <div className="mb-4">
        <div className="mb-4 d-flex justify-content-between">
            {isMultipleSelected ? (
                <div className="d-flex">
                    <div style={{marginRight: "10px"}}>
                        <Dropdown>
                            <Dropdown.Toggle variant="outline-secondary" id="dropdown-basic">
                                <i className="bi bi-chevron-down"></i>   {t("Action")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item onClick={(e) => { e.preventDefault(); setAction("Download"); setIsMultipleSelected(false);}}><i className="bi bi-download"></i> &nbsp; {t("Download")}</Dropdown.Item>
                                { isAdmin ? <Dropdown.Item onClick={(e) => { e.preventDefault(); setAction("Archive"); setIsMultipleSelected(false);}} className="text-danger"><i className="bi bi-archive"></i> &nbsp; {t("Archive")}</Dropdown.Item> : (<></>)}
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                    <div>
                    <Button 
                        onClick={(e) => { e.preventDefault(); action == "SelectAll" ? setAction("UnselectAll") : setAction("SelectAll");}}
                        variant="outline-secondary"
                        style={{marginRight: "10px"}}>{action == "SelectAll" ? t("Unselect All") : t("Select All")}</Button>
                    <Button 
                        onClick={(e) => { e.preventDefault(); setIsMultipleSelected(false); setAction("");}}
                        variant="danger"><i className="bi bi-x-lg"></i></Button>
                    </div>
                </div>
                ) : (
                <div>
                    <Button 
                        onClick={(e) => { e.preventDefault(); setIsMultipleSelected(true)}}
                        variant="outline-secondary">{t("Select")}</Button>
                </div>
            )}
            {isProcessedPage ? (<></>) : (<>
                <div className="d-flex">
                    {!folderView && (<>
                        <div className="mr-2">
                            { !isEqual(filterParams, defaultParams) &&
                                <Button
                                    variant="outline-danger"
                                    onClick={(e) => {
                                        e.preventDefault();
                                        clearFilters();
                                    }}
                                >
                                    {t("Clear Filters")}
                                </Button>
                            }
                            <Button
                            style={{ marginLeft: "10px"}}
                            variant="default"
                            id="filter"
                            onClick={(e) => {
                                e.preventDefault();
                                setShowFilterModal(true);
                            }}> <i className="bi bi-funnel"></i> {t("Filter")}</Button>
                        </div> 
                        <div className="mr-2">
                            <Dropdown>
                                <Dropdown.Toggle variant="default" id="dropdown-basic">
                                    <i className="bi bi-chevron-down"></i>   {t("Items per page")}
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                    <Dropdown.Item className={itemsPerPage == 50 ? "active" : ""} onClick={(e) => { e.preventDefault(); setItemsPerPage(50) }}>50</Dropdown.Item>
                                    <Dropdown.Item className={itemsPerPage == 100 ? "active" : ""} onClick={(e) => { e.preventDefault(); setItemsPerPage(100) }}>100</Dropdown.Item>
                                    <Dropdown.Item className={itemsPerPage == 200 ? "active" : ""} onClick={(e) => { e.preventDefault(); setItemsPerPage(200) }}>200</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                        </>
                    )}
                    <div>
                        <Dropdown>
                            <Dropdown.Toggle variant="default" id="dropdown-basic">
                                <i className="bi bi-justify"></i>   {t("View")}
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                <Dropdown.Item className={folderView ? "active" : ""} onClick={(e) => { e.preventDefault(); setFolderView(true) }}>
                                    <i className="bi bi-view-list"></i> {t("Folders")}
                                </Dropdown.Item>
                                <Dropdown.Item className={!folderView ? "active" : ""} onClick={(e) => { e.preventDefault(); setFolderView(false) }}>
                                    <i className="bi bi-view-stacked"></i> {t("List")}
                                </Dropdown.Item>
                            </Dropdown.Menu>
                        </Dropdown>
                    </div>
                </div>
            </>)}
        </div>
        <div className="mb-4">
            {domain ?
                (folderView ? (
                    <ListFolders 
                        domain={domain} 
                        action={action}
                        isMultipleSelected={isMultipleSelected}
                        isProcessedPage={isProcessedPage}></ListFolders>
                ) : (
                    <ListFiles 
                        domain={domain} 
                        setFilesLoading={setFilesLoading} 
                        filterParams={filterParams}
                        itemsPerPage={itemsPerPage} 
                        isMultipleSelected={isMultipleSelected}
                        action={action}></ListFiles>
            )) : (<Spinner></Spinner>)}
        </div>
        </div>

        <Modal show={syncing} onHide={() => { }}>
            <Modal.Header>
                <Modal.Title>{t("Synchronising...")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {t("Synchronised (x) files", { numberOfFilesProcessed: numberOfFilesProcessed })}
            </Modal.Body>
        </Modal>

        <Modal show={showFilterModal} onHide={() => setShowFilterModal(false)} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>{t('Filter Files')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Container>
                <Row>
                    <Col style={{ padding: "40px" }}>
                        <h1 style={{ fontSize: "1.2rem" }}>Device ID</h1>
                        <List
                            sx={{
                                width: '100%',
                                maxWidth: 360,
                                position: 'relative',
                                overflow: 'auto',
                                maxHeight: 300,
                            }}
                        >
                            {devices.map((device) => (
                                <ListItem key={device}>
                                    <Form.Check
                                        type="checkbox"
                                        id={device}
                                        label={device}
                                        key={device}
                                        checked={selectedDevices.includes(device)}
                                        onChange={(e) => {
                                            selectedDevices.includes(device) ?
                                            setSelectedDevices(selectedDevices.filter(item => item !== device)) :
                                            setSelectedDevices([...selectedDevices, device])
                                        }}
                                    />
                                </ListItem>
                            ))}
                        </List>
                    </Col>
                    <Col style={{ borderLeft: "2px solid grey", padding: "40px" }} className="d-flex flex-column justify-content-center">
                        <Row className="my-4 justify-content-center">
                            <h1 style={{ fontSize: "1.2rem" }}>{t("Size")}</h1>
                            <Col xs="11" className="my-2">
                                <Slider
                                    value={selectedSizeRange}
                                    min={0}
                                    step={1}
                                    max={33}
                                    scale={(v) => (v === 0 ? 0 : 2 ** v)}
                                    getAriaValueText={valueLabelFormat}
                                    valueLabelFormat={valueLabelFormat}
                                    onChange={(e, v) => {
                                        e.preventDefault();
                                        setSelectedSizeRange(v as number[]);
                                    }}
                                    marks={[{value: 0, label: "0B"}, {value: 33, label: "8GB"}]}
                                    valueLabelDisplay="auto"
                                    aria-labelledby="non-linear-slider"
                                />
                            </Col>
                        </Row>
                        <Row className="my-4 justify-content-center">
                            <h1 style={{ fontSize: "1.2rem" }}>{t("Last Modified")}</h1>
                            <Col xs="auto" className="my-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateRangePicker
                                        disableFuture
                                        value={selectedModDateRange}
                                        localeText={{ start: 'Start', end: 'End' }}
                                        format="DD-MM-YYYY"
                                        onChange={(v) => {
                                            setSelectedModDateRange(v as DateRange<Dayjs>);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Col>
                        </Row>
                        <Row className="my-4 justify-content-center">
                            <h1 style={{ fontSize: "1.2rem" }}>{t("Recording Date (LEAP & DELTA)")}</h1>
                            <Col xs="auto" className="my-2">
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                    <DateRangePicker
                                        disableFuture
                                        value={selectedRecDateRange}
                                        localeText={{ start: 'Start', end: 'End' }}
                                        format="DD-MM-YYYY"
                                        onChange={(v) => {
                                            setSelectedRecDateRange(v as DateRange<Dayjs>);
                                        }}
                                    />
                                </LocalizationProvider>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="justify-content-end mt-3">
                    <Col xs="auto">
                        <Button
                            className="m-1"
                            variant="danger"
                            onClick={(e) => {
                                e.preventDefault();
                                setSelectedDevices(defaultParams.selectedDevices);
                                setSelectedSizeRange(defaultParams.selectedSizeRange);
                                setSelectedModDateRange(defaultParams.selectedModDateRange);
                                setSelectedRecDateRange(defaultParams.selectedRecDateRange);
                            }}
                        >
                            {t("Clear")}
                        </Button>
                        <Button
                            className="m-1 primary"
                            onClick={(e) => {
                                e.preventDefault();
                                setFilterParams({
                                    selectedDevices: selectedDevices,
                                    selectedSizeRange: selectedSizeRange,
                                    selectedModDateRange: selectedModDateRange,
                                    selectedRecDateRange: selectedRecDateRange,
                                });
                                setShowFilterModal(false);
                            }}
                        >
                            {t("Filter")}
                        </Button>
                    </Col>
                </Row>
            </Container>
            </Modal.Body>
        </Modal> 
    </>
}